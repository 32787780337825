import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "expressOfferModalToggle"];

  connect() {
    console.log("ExpressOffer controller connected");
  }

  openModal(event) {
    event.preventDefault();
     const expressModal = FlowbiteInstances.getInstance(
        "Modal",
        "express-offer-modal"
    );
    expressModal.show();
  }

  closeModal() {
    const expressModal = FlowbiteInstances.getInstance(
      "Modal",
      "express-offer-modal"
    );
    expressModal.hide();
  }

  validateSelection(event) {
    // Optional: Validate selection before submission
  }

  handleSuccess(event) {
    const [data, status, xhr] = event.detail;
    // Check response or use data from the server to decide next steps
    // Redirect if successful
    console.log("Submission successful");
    window.location.href = "/success_page";
  }

  handleError(event) {
    const [data, status, xhr] = event.detail;
    // Handle errors, possibly display error messages within the modal
    console.error("Submission failed");
  }
}
