import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["depositLow", "depositLarge", "withdrawal", "netDeposit", "netFlow"];

  connect() {
    this.updateCalculatedFields();
  }

  updateCalculatedFields() {
    const depositLow = parseFloat(this.depositLowTarget.value) || 0;
    const depositLarge = parseFloat(this.depositLargeTarget.value) || 0;
    const withdrawal = parseFloat(this.withdrawalTarget.value) || 0;
    const netFlow = depositLow - withdrawal;
    this.netFlowTarget.value = netFlow.toFixed(0);
    const netDeposit = depositLow - depositLarge;
    this.netDepositTarget.value = netDeposit.toFixed(0);
  }
}