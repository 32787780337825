import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["modal", "email"]

    connect() {
    }

    open(event) {
        event.preventDefault();
        this.modalTarget.classList.remove("hidden");
    }

    close(event) {
        event.preventDefault();
        this.modalTarget.classList.add("hidden");
    }

    validateEmail(event) {
        const email = this.emailTarget.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            this.emailTarget.setCustomValidity("");
        } else {
            this.emailTarget.setCustomValidity("Please enter a valid email address.");
        }
    }
}
