import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item']

  connect() {
    this.onVisit = (event) => {
      const curPath = event.detail.url.replace(location.origin, '')
      this.itemTargets.forEach(el => {
        if (curPath.startsWith(el.dataset.path)) {
          el.classList.add('active')
        } else if (el.classList.contains('active')) {
          el.classList.remove('active')
        }
      });
    }
    this.onVisit({detail: { url: location.href }})
    document.documentElement.addEventListener('turbo:visit', this.onVisit)
  }

  disconnect() {
    document.documentElement.removeEventListener('turbo:visit', this.onVisit)
  }
}
