import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="offer-cashflow"
export default class extends Controller {

  static targets = [ "activeCashFlowForm" ]

  connect() {
  }

  updateActiveCashflow(event) {
    this.activeCashFlowFormTarget.requestSubmit();
  }
}
