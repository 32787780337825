import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="remote"
export default class extends Controller {
  static values = {
    url: String,
    method: { type: String, default: 'get' },
    turboFrame: String
  }

  connect() {
  }

  fetch(event) {
    const target = event.target
    let url = document.location

    const params = new URLSearchParams(url.search)
    if (target.value) {
      params.set(target.name, target.value)
    } else {
      params.delete(target.name)
    }

    url = new URL(`${url.origin}${url.pathname}?${params}`)

    if (this.turboFrameValue) {
      Turbo.visit(url.toString(), { frame: this.turboFrameValue })
    } else {
      // TODO: handle other types of requests
      // const request = new FetchRequest(
      //   this.methodValue,
      //   url,
      //   { responseKind: 'turbo-stream' }
      // );
      // await request.perform();
    }
  }
}
