import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit", "checkbox"];

  connect() {
    console.log("Decisions controller connected");
  }

  toggleAll(event) {
    const isChecked = event.target.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
  }

  check() {
    const isAnyChecked =
      this.element.querySelectorAll('input[type="checkbox"]:checked').length >
      0;
    this.submitTarget.disabled = !isAnyChecked;
  }
}
