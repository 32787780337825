import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "broker_id" ]

  filter() {
    this.element.textContent = "Test"
  }
}
