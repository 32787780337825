import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts";

// Connects to data-controller="apex-chart"
export default class extends Controller {
  static values = {
    options: String,
    series: String,
  }

  connect() {
    const options = JSON.parse(this.optionsValue)
    const series = JSON.parse(this.seriesValue)
    // console.log('options', options)
    // console.log('series', series)
    const chart = new ApexCharts( this.element, { ...options, series });
    chart.render();
  }
}
