import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="inbox"
export default class extends Controller {
  static targets = [
    "followUpDate",
    "followUpSubmitButton",
    "followUpModalToggle"
  ];

  scheduleFollowUp(event) {
    this.followUpOffer = event.params.id
  }

  async processing(action) {
    const btn = this.followUpSubmitButtonTarget;
    const originalText = btn.innerText;
    btn.innerText = "Updating..."
    btn.disabled = true;
    try {
      await action();
    } finally {
      btn.innerText = originalText;
      btn.disabled = false;
    }
  }

  async submitFollowUp() {
    const followUpModal = FlowbiteInstances.getInstance('Modal', 'follow-up-modal');
    try {
      await this.processing(async () => {
        const body = JSON.stringify({ date: this.followUpDateTarget.value });
        const request = new FetchRequest(
          'post',
          `/offers/${this.followUpOffer}/followup`,
          { body, responseKind: 'turbo-stream' }
        );
        await request.perform();
        this.followUpDateTarget.value = ""
      });
    } finally {
      followUpModal.hide();
    }
  }

  followUpDateChanged() {
    const valid = this.followUpDateTarget.checkValidity();
    this.followUpSubmitButtonTarget.disabled = !valid;
  }
}
