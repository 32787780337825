import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  static targets = [ "searchForm", "filterForm" ]
  
  connect() {
  }
  
  toggleFilter(event) {
    const target = event.target
    const parent = event.target.parentElement
    const filterName = target.dataset.filter || parent.dataset.filter;

    const filterEl = document.getElementById(`search_filters-${filterName}`)
    const checkboxFilterEl = document.getElementById(`search_filters-${filterName}-checkbox`)
    if(checkboxFilterEl.checked) {
      checkboxFilterEl.checked = false
    } else {
      checkboxFilterEl.checked = true
    }
    this.searchFormTarget.requestSubmit()
  }
}
