import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="followup"
export default class extends Controller {
  connect() {
  }

  async moveToInbox(event) {
    const request = new FetchRequest(
      'post',
      `/offers/followups/${event.params.id}/inbox`,
      { responseKind: 'turbo-stream' }
    );
    await request.perform();
  }
}
