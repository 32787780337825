import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["close"];

  connect() {
    this.element.classList.add("modal-open");
  }

  close() {
    this.element.classList.remove("modal-open");
  }

  closeTargetConnected(element) {
    this.close();
  }
}
